#error-image {
    height: 30vh;
    width: 30vh;
}

.errorContainer {
    text-align: center;
}

.errorText {
    font-size: calc(7px + 1vmax);
}