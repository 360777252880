.homepage {
    display: grid;
    text-align: center;
}

.mockup {
    height: 65vmin;
    width: 30vmin;
    grid-column: 1;
}

.store-info {
    display: grid;
    margin-top: 1vh;
    grid-column: 2;
    justify-self: center;
}

@media only screen and (max-width: 568px) {
    .store-info {
        grid-column: 1;
    }
}

.business-name {
    color: black;
    font-size: 5vmax;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

#established {
    font-size: 2vmax;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: black;
}