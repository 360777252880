.storebuttonbox {
    margin-top: 3vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    max-height: 30vh;
    max-width: 90vw;
    text-align: center;
}

#playstorebutton {
    margin-left: 5vw;
}

#appstorebutton {
    border-style: none;
}

.playstorebuttons {
    min-width: 15vmax;
    width: fit-content;
    min-height: 10vmin;
    height: fit-content;
    font-family: 'Roboto', sans-serif;
    font-size: 1em;
    text-transform: uppercase;
    font-weight: 500;
    vertical-align: middle;
    color: #000;
    background-color: #fff;
    border: none;
    border-radius: 12px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
}

.appstorebuttons {
    min-width: 15vmax;
    width: fit-content;
    min-height: 10vmin;
    height: fit-content;
    font-family: 'Roboto', sans-serif;
    font-size: 1em;
    text-transform: uppercase;
    font-weight: 500;
    vertical-align: middle;
    color: #000;
    background-color: #fff;
    border: none;
    border-radius: 12px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
}

.appstorebuttons:hover {
    border: none;
    outline: none;
    box-shadow: 5px 10px;
    /* background-color: rgb(73, 148, 247); */
    background-color: rgb(168, 128, 238);
    box-shadow: 0px 15px 20px rgb(168, 128, 238, 0.4);
    color: #fff;
    transform: translateY(-7px);
}

.playstorebuttons:hover {
    border: none;
    outline: none;
    box-shadow: 5px 10px;
    background-color: rgb(247, 180, 73);
    box-shadow: 0px 15px 20px rgba(248, 153, 29, 0.4);
    color: #fff;
    transform: translateY(-7px);
}

.app-type {
    color: black;
    font-size: 2vmax;
}

.storebuttontext {
    vertical-align: middle;
}

#play-store-icon {
    height: 4vmin;
    width: 4vmin;
    margin-left: 1vw;
    vertical-align: middle;
}

#app-store-icon {
    margin-left: 1vw;
    height: 4vmin;
    width: 4vmin;
    vertical-align: middle;
}

.button-contents {
    margin: .5rem;
}