.group {
    border: none;
    padding: 15px 20px;
    border-radius: 25px;
    text-align: center;
}

.input {
    border: none;
    padding: 15px 20px;
    border-radius: 25px;
    background: white;
    min-height: 3vh;
    max-height: 5vh;
    width: 40vw;
    font-size: calc(7px + 1vw);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

.input:active {
    border: none;
    outline: none;
    outline-style: none;
}

.input:focus {
    border: none;
    outline: none;
    outline-style: none;
}

input[data-type="password"] {
    -webkit-text-security: circle;
}

label {
    color: black;
    padding: 15px 20px;
    font-size: calc(7px + 1vw);
}

.button {
    width: 140px;
    height: 45px;
    font-family: 'Roboto', sans-serif;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 500;
    color: #000;
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    background-color: white;
    cursor: pointer;
    outline: none;
}

.button:hover {
    background-color: rgb(0, 250, 175);
    box-shadow: 0px 15px 20px rgba(0, 250, 175, 0.411);
    color: #fff;
    transform: translateY(-7px);
}

body {
    background: whitesmoke;
    text-align: center;
}

.update-logo {
    height: 10vh;
    width: 10vh;
}

.ErrorMsg {
    color: red;
}